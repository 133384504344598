import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
// import Hero from '../components/Hero';
import { Hero } from '../components/bulma';
// import Slideshow from '../components/Slideshow';
import Blurbs from '../components/Blurbs';
import ContentSection from '../components/ContentSection';
import FinalSection from '../components/FinalSection';
import BlogSection from '../components/BlogSection';
import CTA from '../components/CTA';
import Analyzer from '../components/Analyzer';

export default function SavingsCalculatorPage({ data }) {
	const { markdownRemark: page } = data;
	const { image, heading, subheading, sections, blurbs, final } = page.frontmatter;

	return (
		<Layout>
			<SavingsCalculatorPageTemplate
				image={image}
				heading={heading}
				subheading={subheading}
				blurbs={blurbs}
				sections={sections}
				final={final}
			/>
		</Layout>
	);
}

export const SavingsCalculatorPageTemplate = ({ image, heading, subheading, sections, blurbs, final }) => (
	<div>
		{/* <Sky
			images={{
				0: amex,
				1: discover,
				2: mastercard,
				3: visa
			}}
			how={10}
			time={500}
			size={'50px'}
		/> */}
		<br />
		<Hero>
			<Analyzer heading={heading} subheading={subheading} />
		</Hero>
		<Blurbs box items={blurbs} />
		<ContentSection items={sections} box />
		{/* <FinalSection content={final} /> */}
		<CTA />
		<BlogSection />
	</div>
);

export const SavingsCalculatorPageQuery = graphql`
	query SavingsCalculatorPage {
		markdownRemark(frontmatter: { templateKey: { eq: "savings-calculator-page" } }) {
			frontmatter {
				title
				heading
				subheading
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
				blurbs {
					image {
						childImageSharp {
							fluid(maxWidth: 240, quality: 64) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
					title
					text
				}
				sections {
					title
					text
					color
					side
					image {
						childImageSharp {
							fluid(maxWidth: 240, quality: 64) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				final {
					title
					text
					# image {
					# 	childImageSharp {
					# 		fluid(maxWidth: 240, quality: 64) {
					# 			...GatsbyImageSharpFluid_withWebp_tracedSVG
					# 		}
					# 	}
					# }
				}
			}
		}
	}
`;
